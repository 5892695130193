<template>
    <div class="home">
<!--        <x-header>实验分类管理</x-header>-->
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp">
                      <el-col :span="5">
                        <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入分类名称搜索">
                           <template slot="suffix">
                                <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                            </template>
                        </el-input>
                    </el-col>
                    <div style="display: flex; justify-content: flex-end;">
                          <el-button size="medium" type="primary" @click="openDialog" icon="base-icon-plus" >
                                    <span> 新增实验分类</span>
                            </el-button>
                    </div>
                  
                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="datas"
                    stripe
                    style="width: 100%">
                    <el-table-column
						prop="name"
                        label="分类名称">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                        label="所属项目">-->
<!--						<template slot-scope="scope">-->
<!--                            {{scope.row.projectId| getProjectName(that)}}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                        label="分类属性">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{scope.row.type| getType(that)}}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column
                        width="140"
                        label="操作">
                        <template slot-scope="scope">
                           <el-button type="primary" size="mini" @click="editProjectType(scope.row)">编辑</el-button>
							<el-button type="info" size="mini" @click="deleteProjectType(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="pageChange"
                        background
                        layout="total,prev, pager, next"
					    					unitText="条数据"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
        <app-dialog ref="projectTypeDialog" :dialogTitle="projectTypeDialogTitle+'实验分类'" originTitle staticDialog width="600px">
          <el-form ref="form"  :rules="rules" :model="formItem">
<!--						<el-form-item prop="projectId" label="所属项目">-->
<!--							<el-select v-model="formItem.projectId" style="width:100%;">-->
<!--										<el-option :value="item.id" :label="item.groupName" v-for="(item,index) in groupList" :key="'group' + index"></el-option>-->
<!--								</el-select>-->
<!--						</el-form-item>-->
<!--						<el-form-item prop="type" label="分类属性">-->
<!--							<el-select v-model="formItem.type" style="width:100%;"  placeholder="请选择分类属性">-->
<!--										<el-option :value="item.key" :label="item.value" v-for="(item,index) in typeList" :key="index"></el-option>-->
<!--								</el-select>-->
<!--						</el-form-item>-->
						<el-form-item prop="name" label="分类名称">
							<el-input placeholder="请输入分类名称" v-model="formItem.name">
					</el-input>
						</el-form-item>
<!--					<el-form-item prop="remarks" label="分类说明">-->
<!--							<el-input type="textarea" placeholder="请输入分类说明" v-model="formItem.remarks">-->
<!--					</el-input>-->
<!--				</el-form-item>-->
					</el-form>
            <div class="save_btn_warp">
                <el-button type="primary" style="width:50%;" size="medium" @click="saveData">保存</el-button>
            </div>
        </app-dialog>
    </div>
</template>
<script>
    import { projectTypeList,projectTypeSave,projectTypeDetele } from "../../service/projectType"
    import { GetExperimentByCategory } from "../../service/experiment"
	import { GetMyProjectList } from "../../service/user"
    export default {
        name:'ProjectTypeManage',
        data(){
            return {
              projectId:'',
                loading:false,
                searchText:'',
                datas:[],
                page:1,
                pageSize:10,
                total:0,
				that: this,
                formItem:{
                    projectId:'',
                    name:'',
                    type:'',
                    remarks:''
				},
				rules:{
                    projectId:[{required:true,message:'请选择所属项目',trigger:'change'}],
                    name:[{required:true,message:'请输入分类名称',trigger:'blur'}],
					type:[{required:true,message:'请选择分类类别',trigger:'change'}],
                },
                groupList:[],
                typeList:[
                    {key:'1',value:'用户实验'},
                    {key:'2',value:'值班日志'},
                ],
                projectTypeDialogTitle:''
            }
        },
        filters: {
            getProjectName(id, vue) {
                const obj = vue.groupList.find((item) => { return item.id === id })
                    if(obj){
                    return obj.groupName
                    }
                    return id
            },
            getType(key, vue) {
                const obj = vue.typeList.find((item) => { return item.key === key })
                    if(obj){
                    return obj.value
                    }
                    return key
            }
			},
        created(){
            // this.getAllData();
            // this.getMyGroups();
        },
        computed:{
  
        },
        methods:{
			openDialog(){
                this.projectTypeDialogTitle="新增"
                this.$refs.projectTypeDialog.show();
			},
            editProjectType(data){
                this.projectTypeDialogTitle="编辑"
                this.formItem = data;
                this.$refs.projectTypeDialog.show();
            },
            saveData(){
                this.$refs['form'].validate(valid => {
                    if (valid) {
                         this.formItem.projectId=this.projectId
                        projectTypeSave(this.formItem).then(e => {
                            let data = this.APIParse(e);
                            if(data){
                                this.$message.success(this.projectTypeDialogTitle+'成功')
                                this.$refs['form'].resetFields();
                                                        this.$refs.projectTypeDialog.hide();
                                this.getAllData(this.projectId);
                            }else{
                                this.$message.error(this.projectTypeDialogTitle+'项目分类失败，请重试')
                            }
                        }).catch(e => {
                            this.$message.error(e.message)
                        })
                    } else {
                        setTimeout(() => {
                        document.getElementsByClassName("is-error")[0].scrollIntoView({
                            behavior: "smooth",
                             block: "center"
                         });
                    }, 0);
                        return false;
                    }
                })
        		},
            getMyGroups(){
                GetMyProjectList().then(e => {
                    let data = this.APIParse(e);
                    this.groupList = data
                }).catch(e => {
                    this.$message.error('项目列表加载失败')
                })
            },
            getAllData(groupId){
              this.projectId=groupId;
                this.loading = true;
                projectTypeList(
				          	this.searchText,
                    groupId,
                    this.page,
                    this.pageSize,
                ).then(e => {
                    let data = this.APIParse(e);
                    this.datas = data.records || [];
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(() => {
                    this.loading = false
                })
            },
            search(){
                this.page = 1;
                this.getAllData(this.projectId);
            },
            pageChange(){
                this.getAllData(this.projectId);
            },
						deleteProjectType(item){
							this.$confirm('此操作将永久删除该项目类别, 是否继续?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
                GetExperimentByCategory(item.id,this.projectId).then(e=>{
                  let data = this.APIParse(e)
                  if (data>0){
                    return this.$message.error('该类别下存在实验，不允许删除')
                  }else{
                    projectTypeDetele(item.id).then(e => {
                      let data = this.APIParse(e)
                      if(data){
                        this.$message.success('项目类别删除成功')
                        this.search();
                      }else{
                        this.$message.error('项目类别删除失败，请重试')
                      }
                    }).catch(ex => {
                      this.$message.error('项目类别删除失败，请重试')
                    })
                  }
                })
							});
						},
        }   
    }
</script>
<style lang="scss" scoped>
    .home{width: 100%;height: 100%;overflow: auto;}
    .content_warp{display: block;}
    .search_warp{width:100%;margin: 16px 0px;}
    .page-warp{width: 100%;text-align: right;margin-top: 8px;}

    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
</style>